import * as React from 'react';
import LayoutConsumer from '../components/layout-context';
import BlogOverviewView from '../components/blog-overview-view';

function BlogOverviewTemplate({ pageContext: data }) {
  const siteTitle = data.site.siteMetadata?.title || 'Title';
  const [posts] = React.useState(data?.allStoryblokEntry?.edges);

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: siteTitle, fullWidth: true });
        return (
          <BlogOverviewView
            posts={posts}
            pageCount={1}
            currentPage={0}
            pageContext={data}
            categories={data?.allStoryblokDatasourceEntry?.nodes ?? []}
          />
        );
      }}
    </LayoutConsumer>
  );
}

export default BlogOverviewTemplate;
