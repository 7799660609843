/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import clsx from 'clsx';
import Seo from './seo';
import FancyTitle from './fancy-title';
import BlogGridView from './blog-grid-view';
import { useWindowSize } from './layout';

function BlogOverviewView({
  posts,
  pageCount,
  currentPage,
  showLinkToAll = false,
  categories,
  pageContext,
}) {
  const windowSize = useWindowSize();
  const { tag } = pageContext;
  const currentCategory = categories.find((category) => category?.value === tag || (tag === undefined && category.name === 'Alle'));
  const isAllCategory = currentCategory?.value === 'alle';

  return (
    <div className="px-4 lg:px-16 pb-[100px] lg:pb-60">
      <Seo
        robots={pageContext.robots}
        title="Der Shopify & Marketing Blog mit News vom Experten"
        description="Der Blog über Shopify & Shopify Plus in Deutschland. Regelmäßige E-Commerce-News, Marketing-Tipps & Updates für Ihren Erfolg im Onlinehandel."
      />
      <FancyTitle
        text="Marketing-Tipps und Shopify News im Blog"
        className="pt-20 lg:pt-36 text-left"
        tag="h1"
      />
      <p className="lg:text-xl">Wir veröffentlichen in diesem Shopify Blog regelmäßig Blogbeiträge zu Shopify News, Updates und Tipps sowie wichtige Marketing-Informationen und Hacks, um Ihren Erfolg im E-Commerce zu steigern.</p>

      <div className="mt-[60px] lg:mt-20 mx-auto flex gap-4 md:gap-0 -ml-4 lg:-ml-16 w-[calc(100%+2rem)] lg:w-[calc(100%+8rem)] md:min-h-[40px]">
        {currentCategory && windowSize.width <= 768 && (
          <div className="block whitespace-nowrap px-4 py-2 rounded bg-latori-green-dark text-white w-fit ml-4 lg:ml-0">{currentCategory.name}</div>
        )}
        <Swiper
          className="cursor-grab w-full"
          slidesPerView="auto"
          spaceBetween={10}
          slidesOffsetAfter={16}
          breakpoints={{
            768: {
              slidesOffsetBefore: 16,
              slidesOffsetAfter: 16,
            },
            1024: {
              slidesOffsetAfter: 64,
              slidesOffsetBefore: 64,
            },
          }}
        >
          {categories
            .filter((category) => (
              category.name !== currentCategory.name && windowSize.width < 768)
              || windowSize.width >= 768)
            .map((category) => (
              <SwiperSlide
                key={`categories-${category.name}`}
                className="w-auto"
                style={{ width: 'auto' }}
              >
                <Link to={category.name !== 'Alle' ? `/blog/${category.value}` : '/blog'} className={clsx('block whitespace-nowrap px-4 py-2 rounded bg-background-light dark:bg-[#212121] hover:bg-latori-green-dark hover:text-white', category?.value === currentCategory?.value ? '!bg-latori-green-dark text-white' : '')}>{category.name}</Link>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>

      <BlogGridView posts={posts} />

      {pageCount > 1 && (
        <div className="px-6 md:px-8 mt-[100px] md:text-center gap-4">
          {
            currentPage > 1 && (
              isAllCategory ? (
                <Link to={currentPage - 1 === 1 ? '/blog' : `/blog/page/${currentPage - 1}`} className="inline-block">&lt; PREV</Link>
              ) : (
                <Link to={currentPage - 1 === 1 ? `/blog/${currentCategory.value}` : `/blog/${currentCategory.value}/page/${currentPage - 1}`} className="inline-block">&lt; PREV</Link>
              )
            )
          }
          {Array(pageCount).fill(0).map((_, i) => {
            const _page = i + 1;
            return (
              <Link
                key={`page-l-${_page}`}
                to={currentCategory && !isAllCategory ? `/blog/${currentCategory.value}${_page !== 1 ? `/page/${_page}` : ''}` : `/blog${_page !== 1 ? `/page/${_page}` : ''}`}
                className={`w-[42px] h-[42px] rounded-full inline-flex justify-center items-center ${_page === currentPage ? 'bg-latori-green-dark text-white' : ''}`}
              >
                {_page}
              </Link>
            );
          })}
          {
            pageCount > currentPage && (
              isAllCategory ? (
                <Link to={currentPage + 1 === 1 ? '/blog' : `/blog/page/${currentPage + 1}`} className="inline-block">NEXT &gt;</Link>
              ) : (
                <Link to={currentPage + 1 === 1 ? `/blog/${currentCategory.value}` : `/blog/${currentCategory.value}/page/${currentPage + 1}`} className="inline-block">NEXT &gt;</Link>
              )
            )
          }
        </div>
      )}
      {showLinkToAll && (
        <div className="flex justify-center lg:justify-end items-center w-full pt-8">
          <Link to="/all-blog" className="inline-flex text-xs">
            Alle Blogbeiträge
          </Link>
        </div>
      )}
    </div>
  );
}

export default BlogOverviewView;
